export enum Collections {
    instances = 'instances',
    shortlinks = 'shortlinks',
    visits = 'visits',
    stats = 'stats',
    feedbacks = 'feedbacks',
    apiKeys = 'apiKeys',
}

export enum PLANS {
    base = 'base',
    business = 'business',
    enterprise = 'enterprise',
}

export const EMAIL = 'support@beaver.codes';
export const MAILTO = `mailto:${EMAIL}?subject=QR%20Code%20Generator`;
const APP_ID = '43fb86de-9691-45b0-ae86-7af33b1d5c3a';
export const UPGRADE_LINK = `https://www.wix.com/apps/upgrade/${APP_ID}?appInstanceId=`

export const PRIMARY_COLOR = '#116dff';
export const COLORS_SHADES = ["#116dff", "#0d5ede", "#094fbe", "#06419e", "#043380", "#022663", "#011948", "#000d2e", "#000416", "#000103"];


export const REVIEW_LINK = 'https://www.wix.com/app-market/add-review/43fb86de-9691-45b0-ae86-7af33b1d5c3a'

export const ERROR_KNOWN_THREAD = 'Provided URL is a known thread';
export const KNOWN_THREAD_WARNING = 'The URL you entered is known to be malicious. Please try another URL';

// eslint-disable-next-line max-len
export const EMPTY_SPACE_SEARCH_IMAGE = 'https://api.lingoapp.com/v4/assets/6F4EC24D-ED41-4136-8050-837D05883F22/preview?size=480&asset_token=SIFGewyrciPVMNHqk6Nw3TI45qyWdJGJ2NOTGxR9FRA&hash=9d772b37ac8c805e780945a422a353b980b28e45&p=complete';
